import React from 'react';

function Testemunho(){
    return <section id="testemunho">
        <div className="container">

        <div id="carouselExampleInterval" className="carousel carousel-dark  slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="5000">
                        <h2>Para ficar despreocupado no dia-a-dia.</h2>
                        <img src="Images/papaiz_1.jpg" alt="Cliente 1"/>                
                        <em>Temos as melhores marcar</em>  
                    </div>      

                    <div className="carousel-item" data-bs-interval="5000">
                        <h2>Todos os tipos de chaves codificadas.</h2>
                        <img src="Images/chave-codigo.jpg" alt="Cliente 2"/>
                        <em>Melhor preços da região</em>  
                    </div>      

                    <div className="carousel-item" data-bs-interval="5000">
                        <h2>Carimbos para quem quer conforto e precisão.</h2>
                        <img src="Images/Linha-Carimbos-Automaticos.jpg" alt="Cliente 2"/>
                        <em>A partir de R$ 20,00</em>  
                    </div>      

                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        
        </div>
    </section>;
  }

export default Testemunho;