import React from 'react';

function Banner(){
    return <section id="banner">
        <div className="container">
            <div className="row">

                <div className="col-lg-6">
                    <h1>Há mais de 27 anos abrindo portas de confiança e amizades...</h1>
                    <p/>
                    <p/>
                    <h3>A chave certa do seu lar ou da sua empresa!</h3>
                </div>

                <div className="col-lg-6">
                    <img src="Images/papaiz_2.png" />
                </div>
       
            </div>
        </div>
    </section>;
  }

export default Banner;