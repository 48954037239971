import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';

/* Paginas */
import Site from './site/site.jsx';
import Historia from './app/hst.jsx';

function App(){

  return <BrowserRouter>
    <Route exact path='/' component={Site} />    
    <Route exact path='/app' component={Historia} />    
</BrowserRouter>;
}

export default App;