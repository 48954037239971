import React from 'react';

function Footer(){
  var ano = new Date().getFullYear(); 

    return <section id="footer">    
     <div className="container">
            <p/>
            <p/>
            <p/>
            <p/>
            <p/>
            <p/>
            <ul className="list-unstyled list-inline social text-center">
                <li className="list-inline-item"><a href="https://www.google.com/maps/place/R.+Dr.+Jos%C3%A9+Elias,+296+-+Alto+da+Lapa,+S%C3%A3o+Paulo+-+SP,+05083-030/@-23.5316238,-46.7192943,17z/data=!3m1!4b1!4m6!3m5!1s0x94cef884f95997b5:0xeaffe8f2a7777dad!8m2!3d-23.5316238!4d-46.7167194!16s%2Fg%2F11csd41hkl?authuser=0&entry=ttu"><i className="fa fa-regular fa-map fa-3x" id="map"></i></a></li>
                <li className="list-inline-item"><a href="https://www.facebook.com/chaveiroaltodalapa"><i className="fa fa-facebook fa-3x" id="face"></i></a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/chaveiroaltodalapa"><i className="fa fa-instagram fa-3x" id="insta"></i></a></li>						
                <li className="list-inline-item"><a href="mailto:contato@chaveiroaltodalapa.com.br"><i className="fa fa-envelope fa-3x" id="mail"></i></a></li>
                <li className="list-inline-item"><a href="https://api.whatsapp.com/send?phone=5511998124040"><i className="fa fa-whatsapp fa-3x" id="zap"></i></a></li>
            </ul>
            <p/>
            <p/>
            <p/>
            <p/>
	    </div>
       <div>
        <h4>Rua Dr José Elias, 296 - Alto da Lapa</h4>
        <p/>
            <p/>
       </div>
      <a className="h4" href="mailto:contato@chaveiroaltodalapa.com.br">contato@chaveiroaltodalapa.com.br</a>
      <p/>
            <p/>
            <p/>
            <p/>
            <p/>
            <p/>
      <p><img src="Images/ssl_cartao-remover.png" alt="" height="120" />
      </p>
      <p className="h6">© Desenvolvido por GEA - Grupo Editora Alphaville - {ano}</p>

    </section>
  }

export default Footer;