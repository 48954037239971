import React from 'react';

function Fotos(){
    return <section id="fotos">
        <div className="container">

        <div id="carouselExampleInterval" className="carousel carousel-dark  slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="5000">
                        <h2>Av. Inajar de Souza...</h2>
                        <img src="Images/01.jpg" alt="Cliente 1"/>                
                        <em>O primeiro degrau!</em>  
                    </div>      

                    <div className="carousel-item" data-bs-interval="5000">
                        <h2>Sempre muito atencioso e dedicado...</h2>
                        <img src="Images/02.jpg" alt="Cliente 1"/>
                        <em>Aguardando meus primeiros clientes!</em>  
                    </div>      

                    <div className="carousel-item" data-bs-interval="5000">
                        <h2>Toda dedicação foi reconhecida...</h2>
                        <img src="Images/04.jpg" alt="Cliente 1"/>
                        <em>Cada degrau que subi, me fez chegar aqui!</em>  
                    </div>      

                    <div className="carousel-item" data-bs-interval="5000">
                        <h2>Chaveiro Alto da Lapa...</h2>
                        <img src="Images/07.jpg" alt="Cliente 1"/>
                        <em> </em>  
                    </div>      

                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        
        </div>
    </section>;
  }

export default Fotos;