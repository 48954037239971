import React from 'react';
import Fotos from '../site/Components/fotos';
import Menu from '../site/Components/menu';
import NossaHistoria from '../site/Components/nossahistoria';
import Footer from './Components/footer';

function Historia(){
    return <div>
      <Fotos/>
      <Menu/>
      <NossaHistoria/>
      <Footer/>
    </div>;
  }

export default Historia;