import React from 'react';

function NossaHistoria(){
    return <section id="historia">
        <div className="container">

          <div className="row">

            <div className="col-lg-8">
                <img src="Images/nossa-historia.png" />
            </div>

            <div className="col-lg-4">
              <img src="Images/08.jpg" />
              <img src="Images/Já-Fez-sua-Chave-Reserva_.png" />
              <img src="Images/07.jpg" />
            </div>

          </div>
        </div>
    </section>;
  }

export default NossaHistoria;