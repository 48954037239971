import React from 'react';

function Footer(){
  var ano = new Date().getFullYear(); 

    return <section id="footer">    
     <div className="container">
  <div className="row text-left text-xs-left text-sm-left text-md-left">
    <div className="col-xs-12 col-sm-6 col-md-6">
      <h5>Mais informações</h5>
      <ul className="list-unstyled quick-links">
        <li><a href="#"><i className="fa fa-angle"></i>Termos de uso</a></li>
        <li><a href="#"><i className="fa fa-angle"></i>Política de privacidade</a></li>
        <li><a href="#"><i className="fa fa-angle"></i>Produtos</a></li>
        <li><a href="#"><i className="fa fa-angle"></i>Promoção</a></li>
        <li><a href="#"><i className="fa fa-angle"></i>Rede Social</a></li>
      </ul>
    </div>
    <div className="col-xs-12 col-sm-6 col-md-6">
      <h5>Alguns links úteis</h5>
      <ul className="list-unstyled quick-links">
        <li><a href="#"><i className="fa fa-angle"></i>Intelbras</a></li>
        <li><a href="#"><i className="fa fa-angle"></i>Segurança</a></li>
        <li><a href="#"><i className="fa fa-angle"></i>LGPD</a></li>
        <li><a href="#"><i className="fa fa-angle"></i>Nossa história</a></li>
        <li><a href="#"><i className="fa fa-angle"></i>Parceiros</a></li>
      </ul>
    </div>
  </div>
</div>
      <div>
            <ul className="list-unstyled list-inline social text-center">
                <li className="list-inline-item"><a href="https://www.google.com/maps/place/R.+Dr.+Jos%C3%A9+Elias,+296+-+Alto+da+Lapa,+S%C3%A3o+Paulo+-+SP,+05083-030/@-23.5316238,-46.7192943,17z/data=!3m1!4b1!4m6!3m5!1s0x94cef884f95997b5:0xeaffe8f2a7777dad!8m2!3d-23.5316238!4d-46.7167194!16s%2Fg%2F11csd41hkl?authuser=0&entry=ttu"><i className="fa fa-regular fa-map fa-2x" id="map"></i></a></li>
                <li className="list-inline-item"><a href="https://www.facebook.com/chaveiroaltodalapa"><i className="fa fa-facebook fa-2x" id="face"></i></a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/chaveiroaltodalapa"><i className="fa fa-instagram fa-2x" id="insta"></i></a></li>						
                <li className="list-inline-item"><a href="mailto:contato@chaveiroaltodalapa.com.br"><i className="fa fa-envelope fa-2x" id="mail"></i></a></li>
                <li className="list-inline-item"><a href="https://api.whatsapp.com/send?phone=5511998124040"><i className="fa fa-whatsapp fa-2x" id="zap"></i></a></li>
            </ul>
	    </div>
       <div>
        <a>Rua Dr José Elias, 296 - Alto da Lapa</a>
       </div>
      <a href="mailto:contato@chaveiroaltodalapa.com.br">contato@chaveiroaltodalapa.com.br</a>
      <p className="h6">© Desenvolvido por GEA - Grupo Editora Alphaville - {ano}</p>

    </section>
  }

export default Footer;