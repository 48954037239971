import React from 'react';

function Precos(){
    return <section id="preco">
        <div className="container">

            <div className="row text-center">
                <div className="titulo">
                    <h1>Preços de fechadura</h1>
                    <p>Fechaduras automáticas e manuais. Venha escolher a que melhor lhe atende.</p>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h1>Fechadura tetra</h1></div>
                        <div className="card-body">
                            <h2>à partir de R$ 100,00</h2>
                            <p>Garantia de 3 meses</p>
                            <img src="Images/tetras.jpg" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h1>Digite sua senha</h1>
                        </div>
                        <div className="card-body">
                            <h2>à partir de R$ 550,00</h2>
                            <p>Até 10 senhas</p>
                            <p>Garantia 6 meses</p>
                            <img src="Images/papaiz.jpg" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h1>Tag e senha</h1>
                        </div>
                        <div className="card-body">
                            <h2>à partir R$ 750,00</h2>
                            <p>Leitura chaveiro rfid / biometria</p>
                            <p>Garantia 6 meses</p>
                            <img src="Images/Intelbras.png" alt="" height="250" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
  }

export default Precos;
