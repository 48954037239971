import React from 'react';

function Features(){
    return <section id="features">
        <div className="container">
            <div className="row">

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-heart fa-5x"></i>
                    <h3>Fidelidade e descrição</h3>                    
                    <p>Nossos serviços são prestados por profissionais extremamente capacitados.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-globe-americas fa-5x"></i>
                    <h3>Vamos até você</h3>
                    <p>Atendemos onde você e seu veículo está.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-columns fa-5x"></i>
                    <h3>Organização é tudo</h3>
                    <p>Estamos sempre nos atualizando e trabalhando com as melhores marcas do mercado.</p>
                </div>
                       
            </div>
        </div>
    </section>;
  }

export default Features;
